import React, { useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { checkNotice } from '@/api/home';

import styles from './style.module.scss';
import useReportForGs2User from '@/utils/hooks/useReportForGs2User';
import { traceEvent } from '@wk/wk-gatherer';
import { useUpdateEffect } from 'react-use';
import useMount from '@/utils/hooks/useMount';

const Notice = () => {
  const { t, i18n } = useTranslation('home');
  const [show, setShow] = useState<boolean>(false);
  const reportGs2Params = useReportForGs2User();

  useMount(() => {
    checkNotice().then((res) => {
      setShow(res ?? false);
    });
  });

  useUpdateEffect(() => {
    if (Object.keys(reportGs2Params).length === 0) {
      return;
    }
    traceEvent({
      data: {
        _event: 'U_HP_NoticeExpose',
        ...reportGs2Params,
      },
    });
  }, [reportGs2Params]);

  return show ? (
    <div className={styles.notice}>
      <section className={styles.noticeIcon}>
        <Image src="/imgs/pc/home/notice-icon.svg" width={28} height={28} alt="" />
      </section>
      <section className={styles.noticeMain}>
        <span className={styles.title}>{t('系统通知-标题')}</span>
        <span className={styles.content}>{t('系统通知-内容')}</span>
      </section>
      <section
        className={styles.noticeBtn}
        role="button"
        onClick={() => {
          traceEvent({
            data: {
              _event: 'U_HP_Notice_ViewDetailsClick',
              ...reportGs2Params,
            },
          });
          window.open(`${i18n.language === 'zh' ? '/zh' : ''}/home/profile?page=agreements`);
        }}
      >
        <span className={styles.text}>{t('系统通知-查看详情')}</span>
        <Image src="/imgs/pc/home/right-arrow-icon.svg" width={12} height={12} alt="" />
      </section>
    </div>
  ) : null;
};

export default Notice;
