import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useMount = (effect: () => void) => {
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    effect();
  }, []);
};

export default useMount;
